import { faMinusSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import Switch from 'react-switch';
import { Button, Col, FormFeedback, FormGroup, Input, InputGroup, Label, Row } from 'reactstrap';
import "./dept.css";

export default class Dept extends Component {

    renderTaxSelect = ({ id,rate }) => 
        <option key={id} value={id}>{rate}</option>

    render() {
		// Row can be in error only if the rendered row is > than max rows (regarding device type)
		const rowInError = this.props.id > this.props.maxRows;
        return (
            <React.Fragment>
            <Row key={`Deptrow${this.props.id}`} className={"mb-4 mb-xs-0 overflow-auto m-2 " + (isMobile ? "flex-align-center" : "flex-nowrap") + " " + ( rowInError ? "rowError" : "")} style={{overflow: "scroll", WebkitOverflowScrolling: "touch", maxWidth: "1600px"}}>
                <Col className={`min-width-150 ${isMobile ? 'p-0' : ''}` }>
                    <div className={isMobile ? 'flex-row' : ''}>
                        <Label for="department_button">Tasto Reparto</Label>
                        <FormGroup>
                            <InputGroup>
                                <Input
                                    bsSize="sm"
                                    invalid={this.props.tasto_reparto_valid ? false : true }
                                    type="select"
                                    value={this.props.tasto_reparto ?? 0}
                                    onChange={ e => this.props.handleTastoRepartoChange(this.props.id, this.props.tasto_reparto, e.target.value)} >
                                    {this.props.departments.map(dep => {
                                        return (<>
                                            {dep.disabled && dep.value !== +this.props.tasto_reparto ? null :
                                            <option key={dep.value} style={dep.disabled ? {backgroundColor: 'lightgray'} : null} value={`${dep.value}`} disabled={dep.disabled}>{dep.value}</option>
                                            }
                                        </>)
                                    })}
                                </Input> 
                            </InputGroup>
                        </FormGroup>
                    </div>
                </Col>
                <Col className={`min-width-150 ${isMobile ? 'p-0' : ''}` }>
                    <div className={isMobile ? 'flex-row' : ''}>
                        <Label for="dept_description">Descrizione</Label>
                        <FormGroup>
                            <InputGroup>
                                <Input
                                    bsSize="sm"
                                    invalid={this.props.valid && !rowInError ? false : true }
                                    type="text"
                                    value={this.props.description}
                                    onChange={ e => this.props.handleDescriptionChange(this.props.id, this.props.tasto_reparto, e.target.value)} />
                                <FormFeedback>{ this.props.feedback }</FormFeedback>
                            </InputGroup>
                        </FormGroup>
                    </div>
                </Col>
                <Col className={`min-width-150 ${isMobile ? 'p-0' : ''}` }>
                    <div className={isMobile ? 'flex-row' : ''}>
                        <Label for="tax_rate">Aliquota</Label>
                        <FormGroup>
                            <InputGroup>
                                <Input 
                                    bsSize="sm"
                                    type="select" 
                                    invalid={this.props.tax_valid ? false : true }
                                    onChange={e => this.props.handleDeptTaxChange(this.props.id, this.props.tasto_reparto, e.target.value)} 
                                    value={this.props.id_tax}>
                                        <option value="-1"></option>
                                        <option value="0">Esente IVA</option>
                                        {this.props.taxes.map(this.renderTaxSelect, this)}
                                </Input>
                            </InputGroup>
                        </FormGroup>
                    </div>
                </Col>
                <Col className={`min-width-150 ${isMobile ? 'p-0' : ''}` }>
                    <div className={isMobile ? 'flex-row' : ''}>
                        <Label for="exemption_reason">Motivo Esenzione</Label>
                        <Input 
                            bsSize="sm"
                            type="select" 
                            disabled={this.props.id_tax === 0 ? false : true }
                            onChange={e => this.props.handleExemptionVatChange(this.props.id, this.props.tasto_reparto, e.target.value)} 
                            value={this.props.exemption_reason} >
                                <option value="0">Esclusa (N1)</option>
                                <option value="1">Non Soggetta (N2)</option>
                                <option value="2">Non Imponibile (N3)</option>
                                <option value="3">Esente (N4)</option>
                                <option value="4">Regime del Margine (N5)</option>
                                <option value="5">Operazione non IVA (N6)</option>
                        </Input>    
                    </div>
                </Col>
                <Col className={`min-width-150 ${isMobile ? 'p-0' : ''}` }>
                    <div className={isMobile ? 'flex-row' : ''}>
                        <Label for="price_limit">Limite prezzo</Label>
                        <Input 
                            bsSize="sm"
                            type="text" 
							pattern="[0-9]*"
							inputMode="decimal"
                            invalid={this.props.limit_price_valid ? false : true} 
                            onChange={e => this.props.handleLimitPrice(this.props.id, this.props.tasto_reparto, e.target.value)} 
                            value={this.props.limit_price}></Input>
                    </div>
                </Col>
                <Col className={`min-width-150 ${isMobile ? 'p-0' : ''}` }>
                    <div className={isMobile ? 'flex-row' : ''}>
                        <Label for="default_price">
                            <span className="d-xl-none">Prezzo Default</span>
                            <span className="d-none d-xl-block">Prezzo</span>
                        </Label>
                        <Input 
                            bsSize="sm"
                            type="text" 
							pattern="[0-9]*"
							inputMode="decimal"
                            invalid={this.props.price_valid ? false : true} 
                            onChange={e => this.props.handleDefaultPrice(this.props.id, this.props.tasto_reparto, e.target.value)} 
                            value={this.props.price}></Input>
                    </div>
                </Col>
				{/* 
				* Nascosto perchè non gestito dai dipositivi
                <Col className={`min-width-150 ${isMobile ? 'p-0' : ''}` }>
                    <div className={isMobile ? 'flex-row' : ''}>
                        <Label for="no_sconto"> 
                            <span>No sconto</span>
                        </Label>
                        <Switch
                            className="d-block"
                            size="small"
                            checked={this.props.no_sconto}
                            onChange={ e => this.props.handleNoScontoChange( this.props.id, this.props.tasto_reparto, e)}
                        ></Switch>
                    </div>
                </Col>
				*/}
                <Col className={`min-width-150 ${isMobile ? 'p-0' : ''}` }>
                    <div className={isMobile ? 'flex-row' : ''}>
                        <Label for="vent_iva"> 
                            <span>Vent. IVA</span>
                        </Label>
                        <Switch
                            className="d-block"
                            size="small"
                            checked={this.props.ventilation}
                            onChange={ e => this.props.handleVatVentilationChange( this.props.id, this.props.tasto_reparto, e)}
                        ></Switch>
                    </div>
                </Col>
                <Col className={`min-width-150 ${isMobile ? 'p-0' : ''}` }>
                    <div className={isMobile ? 'flex-row' : ''}>
                        <Label style={{fontSize:'12px'}} > Cod. Ateco </Label>
                        <Input 
                            bsSize="sm"
                            type="select"
                            value={this.props.ateco_code}
                            onChange={ e => this.props.handleAtecoCodeChange( this.props.id, this.props.tasto_reparto, e.target.value)} >
                                {this.props.atecoCodes.map( ( code, index ) => <option key={'atecoCodes'+index} value={code.id}>{code.value}</option> )}
                        </Input>
                    </div>
                </Col>
                <Col className={`min-width-150 ${isMobile ? 'p-0' : ''}` }>
                    <div className={isMobile ? 'flex-row' : ''}>
                        <Label> Tipologia </Label>
                        <Input
                            bsSize="sm"
                            value={this.props.type}
                            onChange={e => this.props.handleTypeChange( this.props.id, this.props.tasto_reparto, e.target.value )}
                            type="select">
                                <option value="0">BENE</option>
                                <option value="1">SERVIZIO</option>
                        </Input>
                    </div>
                </Col>
                <Col className={`min-width-150 ${isMobile ? 'p-0' : ''}` }>
                    <div className={isMobile ? 'flex-row' : ''}>
                        <Label> Modalità di vendita </Label>
                        <Input
                            bsSize="sm"
                            value={this.props.sell_mode}
                            onChange={e => this.props.handleSellModeChange( this.props.id, this.props.tasto_reparto, e.target.value )}
                            type="select">
                                <option value="0">NORMALE VENDITA</option>
                                <option value="1">BUONO MONOUSO - VENDITA/USO</option>
                                <option value="2">BUONO MONOUSO - USO</option>
                                <option value="3">BUONO MONOUSO - VENDITA</option>
                            </Input>
                    </div>
                </Col>

            </Row>
            <Row>
                <div style={{display: 'flex', justifyContent: 'flex-end', width: '100%', paddingBottom: 20, paddingRight: 20}}>
                    <Button
                        color="danger"
                        outline
                        onClick={() => this.props.handleRemoveDeptRow(this.props.id, this.props.tasto_reparto)}
                    >
                        <FontAwesomeIcon icon={faMinusSquare} size="lg"/> Elimina Reparto
                    </Button>
                </div>
            </Row>
            <hr className="d-lg-none" />
        </React.Fragment>
        )
    }
}
