import httpStatus from 'http-status-codes';
import React, { Component } from 'react';
import request from '../../../helpers/request.js';

import DefaultLoader from '../../../layout/Loader/DefaultLoader';
import { linkDeviceToConfiguration } from '../../../services/devices.js';
import { getServices } from '../../../services/services.js';
import Create from '../../Configuration/Create.jsx';
import Edit from '../../Configuration/Edit';

export class TabConfiguration extends Component {
    
    constructor(props){
        super(props);

        this.state = {
            rtConfig: null,
            isConfigurationLoading: true,
            hasRaisedAnError: false,
            errorCode: null,
			hasPlan: null
        }

    }
    
    componentDidMount() {

        this.getConfiguration();
		this.checkIfHasPlan()

    }

    setConfigurationLoader = (isConfigurationLoading) => {
        this.setState({
            isConfigurationLoading: isConfigurationLoading
        })
    }

	checkIfHasPlan = async () => {
		try {
			const services = await getServices(this.props.idDevice);
			if(services && services.status === 200) {
				// setPlan(services.data.data.filter(item => item.requestType === "PLAN").length > 0 ? true : false);
				this.setState({
					hasPlan: services.data.data.filter(item => item.requestType === "PLAN").length > 0 ? true : false
				})
			}
		} catch (error) {
			this.setState({
				rtConfig : [],
				isConfigurationLoading: false,
				hasRaisedAnError: true,
				errorCode: httpStatus.INTERNAL_SERVER_ERROR
			})
		}
	}

    getConfiguration = () => {

        let url = `${process.env.REACT_APP_RT_CONFIGS_ENDPOINT}/${this.props.idConfig}`;
        let config = {
            headers : {
                'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
            }
        }; 

        request(
            process.env.REACT_APP_API_RT_SERVICE_HOST, 
            {
                method: 'GET',
                url : url,
                config : config
            }
        )
            .then( response => {
                this.setState({
                    rtConfig : response.data,
                    isConfigurationLoading: false,
                    hasRaisedAnError: false,
                    errorCode: response.status
                })


            })
            .catch( err => {

                this.setState({
                    rtConfig : [],
                    isConfigurationLoading: false,
                    hasRaisedAnError: true,
                    errorCode: httpStatus.INTERNAL_SERVER_ERROR
                })

            })

    }

    linkConfiguration = async (device_matricola, idConfiguration ) => {
        if(device_matricola){
            const linkDeviceToConfResponse = await linkDeviceToConfiguration(device_matricola, idConfiguration);
        }
    }

    render() {
        return (
            <React.Fragment>
                { this.state.isConfigurationLoading ? 
                    <DefaultLoader /> : 
                    /*this.state.rtConfig === null ? null : */
                    this.state.hasRaisedAnError ? 
                        /*<AlertDanger errorCode={this.state.errorCode} />*/
                        <Create
                            fiscal_number={this.props.fiscalNumber}
                            extraActionToPerformOnSave={this.linkConfiguration}
                            goToList={this.props.toTableList}
                        /> :
                        <Edit 
                            idDevice = {this.props.idDevice} 
                            configurationId={this.props.idConfig} 
                            data={this.state.rtConfig} 
                            isDuplicationAction={true}
                            isFromRTModal={true}
							hasPlan={this.state.hasPlan ?? null}
                        /> 
                }
                <br/>
                
            </React.Fragment>
        )
    }
}

export default TabConfiguration
